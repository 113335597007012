if (module.hot) {
  module.hot.accept();
}
import {tns} from 'tiny-slider/src/tiny-slider';
var scrollToElement = require('scroll-to-element');

const hamburger = $(".hamburger")
const navLinks = $(".nav-links");
const links = $(".nav-links li");

$('.contact').click(function(e){
    e.preventDefault();
    navLinks.toggleClass("open");
    links.toggleClass("fade");
    hamburger.toggleClass("toggle");
    scrollToElement('#form-contact', {
        offset: -50,
        ease: 'linear',
        duration: 1000
    });
})

$('.service').click(function(e){
    e.preventDefault();
    navLinks.toggleClass("open");
    links.toggleClass("fade");
    hamburger.toggleClass("toggle");
    scrollToElement('#services', {
        offset: -50,
        ease: 'linear',
        duration: 1000
    });
})

$('.contact-footer').click(function(e){
    e.preventDefault();
    scrollToElement('#form-contact', {
        offset: -50,
        ease: 'linear',
        duration: 1000
    });
})

$('.service-footer').click(function(e){
    e.preventDefault();
    scrollToElement('#services', {
        offset: -50,
        ease: 'linear',
        duration: 1000
    });
})




hamburger.click(function() {
  //Animate Links
  navLinks.toggleClass("open");
    links.toggleClass("fade");
  //Hamburger Animation
  hamburger.toggleClass("toggle");
});


$(window).scroll(function (event) {
  let scroll = $(window).scrollTop();
  let mobile = window.innerWidth;
 if(scroll > 100 ){
     $('nav.trans').css("visibility","hidden")
     $('nav.trans').css("opacity",0)
     $('nav.fix').css("visibility","visible")
     $('nav.fix').css("opacity",1)
 }
 else{
     $('nav.fix').css("visibility","hidden")
     $('nav.fix').css("opacity",0)
     $('nav.trans').css("visibility","visible")
     $('nav.trans').css("opacity",1)
 }

});

var slider = tns({
    "container": ".my-slider",
    "items": 3,
    "loop": true,
    "autoplay": true,
    "autoplayHoverPause": true,
    "autoplayButtonOutput": false,
    "controls": false,
    "nav": false,
    "speed": 2000,
    "autoplayTimeout": 0,
    "preventActionWhenRunning": true,
    "mouseDrag": true,
    "lazyload": true,
});


var btn = $('#return');

$(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
        btn.addClass('show');
    } else {
        btn.removeClass('show');
    }
});

btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
});

function setCookie(c_name, value, exdays) {
    var exdate = new Date();
    exdate.setDate(exdate.getDate() + exdays);
    var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
    document.cookie = c_name + "=" + c_value;
}

function getCookie(c_name) {
    var i, x, y, ARRcookies = document.cookie.split(";");
    for (i = 0; i < ARRcookies.length; i++) {
        x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
        y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
        x = x.replace(/^\s+|\s+$/g, "");
        if (x == c_name) {
            return unescape(y);
        }
    }
}


$(document).ready(function() {
    if (undefined == getCookie('cookie'))
        $('#cookies').show();
});
$('.cookies-button').click(function() {
    setCookie('cookie',true,365);
    $('#cookies').hide();
});





